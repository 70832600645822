
import { defineComponent, reactive, ref } from "vue";
import Images from "@/components/Images.vue";

interface Group {
  number: number;
  imageType: number;
}

type comparisons = "<" | "=" | ">";

export default defineComponent({
  name: "ViceMene",
  components: {
    Images,
  },
  setup() {
    // init data
    const getImageType = (exceptImage = -1): number => {
      var num = Math.floor(Math.random() * 5);
      return num === exceptImage ? getImageType(exceptImage) : num;
    };

    const initGroup = (exceptImage = -1) => {
      return reactive({
        number: Math.floor(Math.random() * 11),
        imageType: getImageType(exceptImage),
      });
    };

    const first: Group = initGroup();
    const second: Group = initGroup(first.imageType);

    // handle clicks on comparison buttons
    const selected_comparison = ref("");
    const activateComparison = (type: comparisons) => {
      selected_comparison.value = type;
      switch (type) {
        case "<": {
          let audio = new Audio(require("../assets/audio/s2015-meenee.mp3"));
          audio.play();
          break;
        }
        case "=": {
          let audio = new Audio(require("../assets/audio/s1916-stejnee.mp3"));
          audio.play();
          break;
        }
        case ">": {
          let audio = new Audio(require("../assets/audio/viice.mp3"));
          audio.play();
          break;
        }
        default:
          break;
      }
    };

    // resolve
    const resolveStatus = ref(0);
    const resolve = () => {
      let diff = first.number - second.number;
      if (
        (diff < 0 && selected_comparison.value === "<") ||
        (diff === 0 && selected_comparison.value === "=") ||
        (diff > 0 && selected_comparison.value === ">")
      ) {
        resolveStatus.value = 1;
      } else {
        resolveStatus.value = -1;
      }
    };

    return {
      first,
      second,
      activateComparison,
      selected_comparison,
      resolveStatus,
      resolve,
    };
  },
});
