<template>
  <div class="vice-mene">
    <p class="question">
      Je v první skupině méně, stejně nebo více zvířátek než ve druhé?
    </p>
    <div class="images-wrap">
      <Images v-bind="first" />
      <Images v-bind="second" />
    </div>
    <div class="comparison">
      <div class="number">{{ first.number }}</div>
      <div class="buttons">
        <div class="button-wrap">
          <button
            title="Méně"
            :class="{ active: selected_comparison === '<' }"
            @click="activateComparison('<')"
          >
            &lt;</button
          ><br />
          <span>Méně</span>
        </div>
        <div class="button-wrap">
          <button
            title="Stejně"
            :class="{ active: selected_comparison === '=' }"
            @click="activateComparison('=')"
          >
            =</button
          ><br />
          <span>Stejně</span>
        </div>
        <div class="button-wrap">
          <button
            title="Více"
            :class="{ active: selected_comparison === '>' }"
            @click="activateComparison('>')"
          >
            &gt;</button
          ><br />
          <span>Více</span>
        </div>
      </div>
      <div class="number">{{ second.number }}</div>
    </div>
    <div class="resolve">
      <transition-group name="fade">
        <span v-if="resolveStatus > 0" class="success">Výborně!</span>
        <span v-if="resolveStatus < 0" class="failure">Zkus to znovu</span>
      </transition-group>
    </div>
    <button @click="resolve()">Vyhodnotit</button>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import Images from "@/components/Images.vue";

interface Group {
  number: number;
  imageType: number;
}

type comparisons = "<" | "=" | ">";

export default defineComponent({
  name: "ViceMene",
  components: {
    Images,
  },
  setup() {
    // init data
    const getImageType = (exceptImage = -1): number => {
      var num = Math.floor(Math.random() * 5);
      return num === exceptImage ? getImageType(exceptImage) : num;
    };

    const initGroup = (exceptImage = -1) => {
      return reactive({
        number: Math.floor(Math.random() * 11),
        imageType: getImageType(exceptImage),
      });
    };

    const first: Group = initGroup();
    const second: Group = initGroup(first.imageType);

    // handle clicks on comparison buttons
    const selected_comparison = ref("");
    const activateComparison = (type: comparisons) => {
      selected_comparison.value = type;
      switch (type) {
        case "<": {
          let audio = new Audio(require("../assets/audio/s2015-meenee.mp3"));
          audio.play();
          break;
        }
        case "=": {
          let audio = new Audio(require("../assets/audio/s1916-stejnee.mp3"));
          audio.play();
          break;
        }
        case ">": {
          let audio = new Audio(require("../assets/audio/viice.mp3"));
          audio.play();
          break;
        }
        default:
          break;
      }
    };

    // resolve
    const resolveStatus = ref(0);
    const resolve = () => {
      let diff = first.number - second.number;
      if (
        (diff < 0 && selected_comparison.value === "<") ||
        (diff === 0 && selected_comparison.value === "=") ||
        (diff > 0 && selected_comparison.value === ">")
      ) {
        resolveStatus.value = 1;
      } else {
        resolveStatus.value = -1;
      }
    };

    return {
      first,
      second,
      activateComparison,
      selected_comparison,
      resolveStatus,
      resolve,
    };
  },
});
</script>

<style scoped>
.vice-mene {
  width: 50%;
  margin: auto;
}

.images-wrap {
  display: flex;
  justify-content: space-evenly;
}

.comparison {
  display: flex;
  justify-content: center;
}
.comparison .buttons {
  display: flex;
  justify-content: center;
}
.comparison .number {
  font-size: 1.8rem;
  padding: 0.4rem 5rem;
}
.comparison .buttons .button-wrap>span {
  font-size: 0.9rem;
}
.vice-mene button {
  background: white;
  border: 2px solid lightblue;
  padding: 0.8rem 1.6rem;
  outline: none;
  margin-left: -2px;
  cursor: pointer;
  transition: background-color 0.4s ease;
  margin-bottom: 0.6rem;
}
.vice-mene button:hover {
  background-color: rgb(231, 244, 248);
}
.vice-mene button.active {
  background-color: lightblue;
}
.resolve {
  padding: 1.25rem;
  position: relative;
  height: 3.5rem;
  font-weight: 700;
}
.resolve > span {
  padding: 0.5rem 1rem;
  border-radius: 7px;
}
.resolve .success {
  color: #008000;
  background: #E4FBE4;
}
.resolve .failure {
  color: #CC0000;
  background: #FBE1E0;
}
.fade-enter-active {
  transition: opacity 0.4s ease;
}

.fade-enter-from,
.fade-leave-to,
.fade-leave-active {
  opacity: 0;
}

@media only screen and (max-width: 1200px) {
  .vice-mene {
    width: 70%;
  }

  .comparison .number {
    padding: 0.4rem 3rem;
  }
}
@media only screen and (max-width: 800px) {
  .vice-mene {
    width: 90%;
  }
  .comparison .number {
    padding: 0.4rem 1.5rem;
  }
}
</style>
