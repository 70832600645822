
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Images",
  props: {
    number: {
      type: Number,
      required: true,
    },
    imageType: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const classesList = [
      "divocak",
      "eagle",
      "chameleon",
      "chobotnice",
      "liska",
    ];

    const imageClass = ref("");

    if (props.imageType < classesList.length) {
      imageClass.value = classesList[props.imageType];
    } else {
      imageClass.value = classesList[0];
    }

    return {
      imageClass,
    };
  },
});
