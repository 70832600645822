<template>
  <div class="images" :class="imageClass">
    <div class="image" v-for="index in number" :key="index"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Images",
  props: {
    number: {
      type: Number,
      required: true,
    },
    imageType: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const classesList = [
      "divocak",
      "eagle",
      "chameleon",
      "chobotnice",
      "liska",
    ];

    const imageClass = ref("");

    if (props.imageType < classesList.length) {
      imageClass.value = classesList[props.imageType];
    } else {
      imageClass.value = classesList[0];
    }

    return {
      imageClass,
    };
  },
});
</script>

<style scoped>
.images {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  padding: 2rem;
  align-content: center;
  justify-content: center;
  min-height: 15rem;
  max-width: 20rem;
}
.image {
  width: 4rem;
  height: 4rem;
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
}
.divocak .image {
  background-image: url("../assets/img/divocak.svg");
}
.eagle .image {
  background-image: url("../assets/img/eagle.svg");
}
.chameleon .image {
  background-image: url("../assets/img/chameleon.svg");
}
.chobotnice .image {
  background-image: url("../assets/img/chobotnice.svg");
}
.liska .image {
  background-image: url("../assets/img/liska.svg");
}

@media only screen and (max-width: 600px) {
  .image {
    width: 3rem;
    height: 3rem;
  }

  .images {
    padding: 1rem;
    min-height: 13rem;
  }
}
@media only screen and (max-width: 400px) {
  .image {
    width: 2.5rem;
    height: 2.5rem;
  }
}
</style>
